<template>
  <div class="" :id="uid"></div>
</template>

<script>
import {mapState, mapGetters, mapMutations, mapActions} from 'vuex';
// Need full jQuery build
import jQuery from 'jquery';
import MapSVG from '../assets/mapsvg/mapsvg.js';
import {keys} from 'lodash';

export default {
  name: 'MapSvg',

  props: {
    selectedProvinces: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      /**
       * @var jQuery DOM
       */
      el: null,
      selectedRegions: {},
    };
  },

  created() {
  },

  mounted() {
    // Return early if instance is already loaded
    this.id = this._uid;

    if (this.el) return;

    // Store DOM
    this.selectedProvinces.forEach((item) => {
      this.selectedRegions[item.iso3166_2] = {
        fill: '#ed110e',
      };
    });

    const _vm = this;

    this.el = jQuery(this.$el).mapSvg({
      markerLastID: 1,
      width: 900,
      height: 1025,
      lockAspectRatio: true,
      disableAll: false,
      colors: {
        baseDefault: '#000000',
        background: '#acdbfd',
        directory: '#fafafa',
        status: {},
        base: '#e2e8dc',
        stroke: '#515a61',
        disabled: '#ed110e', //c4f395
        hover: '#c4c4c4',
        selected: 0,
      },
      regions: this.selectedRegions,
      viewBox: [-170.11053512195116, 0, 899.7919902439023, 1024.7631],
      cursor: 'pointer',
      zoom: {
        on: false,
        limit: [0, 10],
        delta: 2,
        buttons: {on: true, location: 'right'},
        mousewheel: true,
      },
      onClick: function (e, r, x) {
        if (this instanceof MapSVG.Region) {
          _vm.$emit('onRegionClick', this.id);
        }
      },
      scroll: {on: false, limit: false, background: false, spacebar: false},
      tooltips: {
        mode: (element, region) => {
          if (region instanceof MapSVG.Region) {
            const result = this.provinces.find(
              (item) => item.iso3166_2 == region.id
            );
            return (result || []).name_th;
          }
          return null;
        },
        on: false,
        priority: 'global',
        position: 'bottom-right',
      },
      popovers: {
        mode: false,
        on: false,
        priority: 'local',
        position: 'top',
        centerOn: true,
        width: 300,
        maxWidth: 50,
        maxHeight: 50,
        resetViewboxOnClose: true,
      },
      gauge: {
        on: false,
        labels: {low: 'low', high: 'high'},
        colors: {
          lowRGB: {r: 85, g: 0, b: 0, a: 1},
          highRGB: {r: 238, g: 0, b: 0, a: 1},
          low: '#550000',
          high: '#ee0000',
          diffRGB: {r: 153, g: 0, b: 0, a: 0},
        },
        min: 0,
        max: false,
      },
      source: 'http://localhost:8080/files/thailand.svg',
      title: 'Thailand',
      markers: [
        // {
        //   id: 'marker_1',
        //   attached: true,
        //   isLink: false,
        //   data: {},
        //   src: 'http://localhost:8010/files/location-pin.png',
        //   width: 15,
        //   height: 24,
        //   x: 283.6807858746675,
        //   y: 217.40243686297265,
        //   geoCoords: [17.321977, 101.550121]
        // }
      ],
      responsive: true,
    });

    // .mapSvg({
    //   width: 559.57092,
    //   height: 1024.7631,
    //   viewBox: [0, 0, 559.57092, 1024.7631],
    //   colors: {
    //     baseDefault: '#000000',
    //     background: '#acdbfd',
    //     selected: '#f22c8b',
    //     hover: 20,
    //     directory: '#fafafa',
    //     status: {},
    //     base: '#cfcfcf',
    //     disabled: '#cfcfcf'
    //   },
    //   regions: {
    //     'TH-42': {
    //       id: 'TH-42',
    //       id_no_spaces: 'TH-42',
    //       title: 'Loei',
    //       fill: 'rgba(87,31,31,1)',
    //       data: {}
    //     }
    //   },
    //   zoom: {
    //     on: false,
    //     limit: [0, 10],
    //     delta: 2,
    //     buttons: { on: true, location: 'right' },
    //     mousewheel: true
    //   },
    //   scroll: { on: false, limit: false, background: false, spacebar: false },
    //   tooltips: {
    //     mode: 'off',
    //     on: false,
    //     priority: 'local',
    //     position: 'bottom-right'
    //   },
    //   popovers: {
    //     mode: 'off',
    //     on: false,
    //     priority: 'local',
    //     position: 'top',
    //     centerOn: true,
    //     width: 300,
    //     maxWidth: 50,
    //     maxHeight: 50,
    //     resetViewboxOnClose: true,
    //     mobileFullscreen: true
    //   },
    //   gauge: {
    //     on: false,
    //     labels: { low: 'low', high: 'high' },
    //     colors: {
    //       lowRGB: { r: 85, g: 0, b: 0, a: 1 },
    //       highRGB: { r: 238, g: 0, b: 0, a: 1 },
    //       low: '#550000',
    //       high: '#ee0000',
    //       diffRGB: { r: 153, g: 0, b: 0, a: 0 }
    //     },
    //     min: 0,
    //     max: false
    //   },
    //   source: 'http://localhost:8010/files/thailand.svg',
    //   title: 'Thailand',
    //   responsive: true
    // });
    // jQuery('#mapsvg').mapSvg({
    //   markerLastID: 2,
    //   width: 559.57092,
    //   height: 1024.7631,
    //   viewBox: [0, 0, 559.57092, 1024.7631],
    //   zoom: {
    //     on: false,
    //     limit: [0, 10],
    //     delta: 2,
    //     buttons: { on: true, location: 'right' },
    //     mousewheel: true
    //   },
    //   scroll: { on: false, limit: false, background: false, spacebar: false },
    //   tooltips: {
    //     mode: 'off',
    //     on: false,
    //     priority: 'local',
    //     position: 'bottom-right'
    //   },
    //   popovers: {
    //     mode: 'off',
    //     on: false,
    //     priority: 'local',
    //     position: 'top',
    //     centerOn: true,
    //     width: 300,
    //     maxWidth: 50,
    //     maxHeight: 50,
    //     resetViewboxOnClose: true
    //   },
    //   gauge: {
    //     on: false,
    //     labels: { low: 'low', high: 'high' },
    //     colors: {
    //       lowRGB: { r: 85, g: 0, b: 0, a: 1 },
    //       highRGB: { r: 238, g: 0, b: 0, a: 1 },
    //       low: '#550000',
    //       high: '#ee0000',
    //       diffRGB: { r: 153, g: 0, b: 0, a: 0 }
    //     },
    //     min: 0,
    //     max: false
    //   },
    //   source: '/mapsvg/maps/geo-calibrated/thailand.svg',
    //   title: 'Thailand',
    //   markers: [
    //     {
    //       id: 'marker_1',
    //       attached: true,
    //       isLink: false,
    //       tooltip: 'xxxx',
    //       popover: '4444',
    //       data: { map: 'xxx' },
    //       src: '/mapsvg/markers/pin1_red.png',
    //       width: 15,
    //       height: 24,
    //       x: 260.41408645958086,
    //       y: 313.15092279724854,
    //       geoCoords: [15.633976, 101.31639]
    //     }
    //   ],
    //   responsive: true,
    //   onClick: null,
    //   mouseOver: null,
    //   mouseOut: null
    // });
    // jQuery('#mapsvg').mapSvg({
    //   width: 900,
    //   height: 1025,
    //   lockAspectRatio: false,
    //   colors: {
    //     baseDefault: '#000000',
    //     background: '#acdbfd',
    //     directory: '#fafafa',
    //     status: {},
    //     base: '#e2e8dc',
    //     stroke: '#85939d',
    //     disabled: '#c4f395',
    //     hover: '#c4c4c4',
    //     selected: '#ed110e'
    //   },
    //   regions: { 'TH-10': { disabled: false } },
    //   viewBox: [-170.11053512195116, 0, 899.7919902439023, 1024.7631],
    //   cursor: 'pointer',
    //   tooltips: {
    //     mode: function(some, why) {
    //       return '234';
    //     },
    //     on: false,
    //     priority: 'local',
    //     position: 'bottom-right'
    //   },
    //   popovers: {
    //     mode: function(some) {
    //       console.log('pop over');
    //       return '5555';
    //     },
    //     on: true,
    //     priority: 'local',
    //     position: 'top',
    //     centerOn: true,
    //     width: 300,
    //     maxWidth: 50,
    //     maxHeight: 50,
    //     resetViewboxOnClose: true
    //   },
    //   gauge: {
    //     on: false,
    //     labels: { low: 'low', high: 'high' },
    //     colors: {
    //       lowRGB: { r: 85, g: 0, b: 0, a: 1 },
    //       highRGB: { r: 238, g: 0, b: 0, a: 1 },
    //       low: '#550000',
    //       high: '#ee0000',
    //       diffRGB: { r: 153, g: 0, b: 0, a: 0 }
    //     },
    //     min: 0,
    //     max: false
    //   },
    //   source: '/mapsvg/maps/geo-calibrated/thailand.svg',
    //   title: 'Thailand',
    //   responsive: '1'
    // });

    // jQuery('#mapsvg').mapSvg({
    //   markerLastID: 1,
    //   width: 900,
    //   height: 1025,
    //   lockAspectRatio: false,
    //   colors: {
    //     baseDefault: '#000000',
    //     background: '#acdbfd',
    //     directory: '#fafafa',
    //     status: {},
    //     base: '#e2e8dc',
    //     stroke: '#85939d',
    //     disabled: '#c4f395',
    //     hover: '#c4c4c4',
    //     selected: '#ed110e'
    //   },
    //   regions: {
    //     'TH-10': {
    //       id: 'TH-10',
    //       id_no_spaces: 'TH-10',
    //       title: 'Krung Thep Maha Nakhon (Bangkok)',
    //       tooltip: 'wow',
    //       popover: '2346',
    //       data: {}
    //     }
    //   },
    //   viewBox: [-170.11053512195116, 0, 899.7919902439023, 1024.7631],
    //   cursor: 'pointer',
    //   zoom: {
    //     on: false,
    //     limit: [0, 10],
    //     delta: 2,
    //     buttons: { on: true, location: 'right' },
    //     mousewheel: true
    //   },
    //   scroll: { on: false, limit: false, background: false, spacebar: false },
    //   tooltips: {
    //     mode: function(some, why) {
    //       return '234';
    //     },
    //     on: false,
    //     priority: 'local',
    //     position: 'bottom-right'
    //   },
    //   popovers: {
    //     mode: function(some) {
    //       console.log('pop over');
    //       return '5555';
    //     },
    //     on: true,
    //     priority: 'local',
    //     position: 'top',
    //     centerOn: true,
    //     width: 300,
    //     maxWidth: 50,
    //     maxHeight: 50,
    //     resetViewboxOnClose: true
    //   },
    //   gauge: {
    //     on: false,
    //     labels: { low: 'low', high: 'high' },
    //     colors: {
    //       lowRGB: { r: 85, g: 0, b: 0, a: 1 },
    //       highRGB: { r: 238, g: 0, b: 0, a: 1 },
    //       low: '#550000',
    //       high: '#ee0000',
    //       diffRGB: { r: 153, g: 0, b: 0, a: 0 }
    //     },
    //     min: 0,
    //     max: false
    //   },
    //   source: 'http://localhost:8010/files/thailand.svg',
    //   title: 'Thailand',
    //   markers: [
    //     {
    //       id: 'marker_1',
    //       attached: true,
    //       isLink: false,
    //       tooltip: 'mark tool',
    //       popover: 'mark pop',
    //       data: {},
    //       src: 'http://localhost:8010/files/location-pin.png',
    //       width: 15,
    //       height: 24,
    //       x: 283.6807858746675,
    //       y: 217.40243686297265,
    //       geoCoords: [17.321977, 101.550121]
    //     }
    //   ],
    //   responsive: true
    // });
  },

  computed: {
    ...mapState(['provinces']),
  },

  methods: {
    selectRegion(provinces) {
      if (!this.el) return;

      keys(this.selectedRegions).forEach((key) => {
        const region = this.el.getRegion(key);
        region.setFill('#e2e8dc');
      });

      this.selectedRegions = {};
      provinces.forEach((province) => {
        const region = this.el.getRegion(province.iso3166_2);
        region.setFill('#ed110e');
        this.selectedRegions[province.iso3166_2] = {
          fill: '#ed110e',
          hover: '#ed110e',
        };
      });
    },
    setProvincesColor(provinces, color) {
      if (!this.el) return;
      provinces.forEach((province) => {
        const region = this.el.getRegion(province.iso3166_2);
        region.setFill(color);
      });
    },
    reload() {
      if (!this.el) return;
      this.el.reloadRegions();
    },
  },

  destroyed() {
    if (this.el) {
      this.el.destroy();
    }
  },
};
</script>

<style lang="scss">
@import '../assets/mapsvg/mapsvg.scss';
</style>
